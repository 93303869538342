.topnav {
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.topnav a {
  float: left;
  display: block;
  color: #424242;
  text-align: center;
  padding: 14px 10px;
  text-decoration: none;
  font-size: 100%;
  font-weight: 600;
  /* border-bottom: 3px solid transparent; */
  border-bottom: 1px solid #d3d3d3;
}

.topnav a:hover {
  border-bottom: 3px solid #3e5067;
  color: #3e5067;
}

.topnav a.active {
  border-bottom: 3px solid #3e5067;
  color: #3e5067;
}

.recommended .topnav a {
  padding: 8px 25px;
}
@media (max-width: 500px) {
  .topnav a {
    /*border-bottom: 3px solid #289845;*/
    /*color: #00980d;*/
    font-size: 14px;
  }
  .headings {
    font-size: 100%;
  }
}

@media (max-width: 400px) {
  .topnav a {
    font-size: 12px;
  }
  .headings {
    font-size: 100%;
  }
}
@media (max-width: 318px) {
  .topnav a {
    font-size: 11px;
  }
}

.latest-arrival .container {
  padding-left: 0px;
  padding-right: 0px;
}

.car-accessories {
  background-image: url('../assets/single-elements/Car-accessories.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  /*background-size: contain;*/
}

.campaign-banner {
  background-image: radial-gradient(circle at center,#fff 0,#009898 100%);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}

.campaign-banner-text {
  font-weight: 900;
  font-size: 13px;
  margin-top: 10px;
}

.blink_me {
  animation: blinker 2s linear infinite;
}

a.custom-on-hover:hover {
  color: black !important;
}


@keyframes blinker {
  50% {
    opacity: 0;
  }
}

a.fill-div {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
}

.custom-on-hover a:hover {
  color: yellow;
}


.closeBtn {
  float: left;
}

.electronics {
  background-image: url('../assets/single-elements/all-new-electronics.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.register-banner {
  /*background-image: url('../assets/single-elements/register-banner.jpg');*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.car-accessories,
.electronics {
  color: white;
  padding: 60px 30px;
}
@media (max-width: 545px) {
  .car-accessories,
  .electronics {
    /*margin-bottom: -21px;*/
  }
}

.register-banner{
  padding-top: 70px;
  padding-left: 30px;
}

@media (max-width: 1080px) and (min-width: 768px) {
  .electronics {
    background-image: url('../assets/single-elements/all-new-electronics.jpg');
    background-size: 940px auto;
    image-rendering: none;
    background-repeat: no-repeat;
  }
  .register-banner {
    /*background-image: url('../assets/single-elements/register-banner.jpg');*/
    background-size: 400px auto;
    background-repeat: no-repeat;
    padding-left: 40px;
  }
  .car-accessories {
    background-image: url('../assets/single-elements/Car-accessories.jpg');
    background-position: center center;
    /*padding: 50px 30px;*/
    background-size: 936px auto;
    background-repeat: no-repeat;
    /*margin-top: -27px;*/
  }
}

select option:hover {
  box-shadow: 0 0 10px 100px #1882a8 inset;
}

.car-accessories .electronics,
h6 {
  color: white;
}

.car-accessories .electronics,
h2 {
  font-weight: 600;
}

.shop-now {
  padding: 5px 40px;
  background-color: #a80000;
  color: white;
  font-weight: 200;
  margin-top: 20px;
  border: 1px solid #3e5067;
}

.shop-now:hover {
  background-color: #3e5067;
  border: 1px solid #3e5067;
}

.cif-price {
  background-color: #b80000;
  border: none;
  border-radius: 4px;
  border-color: none;
  font-size: 13px;
  padding: 7px 35px;
  color: #e0e0e0;
  letter-spacing: 1px;
}

/* local services */
#second-hand-car {
  background-image: url('../assets/single-elements/Enter-Your_city.png');
}

.card {
  border: none;
  background-color: rgba(255, 255, 255, 0.41);
}
.card-deck {
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 768px) {
  .card-deck {
    padding-right: 24px;
    padding-left: 0px;
  }
}

.buy-car {
  padding-left: 0px;
}

.looking-to-buy .form-group {
  margin: 2px;
}

.buy-car .card-subtitle {
  margin-top: 12px;
  color: black;
  /* width: 80%; */
  padding: 26px;
  /* padding: 50px 30px 20px 30px; */
  font-size: 8px;
  text-align: center;
}

.recommended .card {
   border: none;
  /*min-height: 230px;*/
}

.best-deal .card {
  /* border: none; */
  /*min-height: 230px;*/
}

.best-deal-cards .card {
  /*border: 1px solid rgba(0, 0, 0, 0.125);*/
}

.buy-car input[type='select'] {
  background-image: url('../assets/single-elements/iconfinder_Tilda_Icons_1ed_location_3586361.png');
  background-position: 2px 2px;
  background-repeat: no-repeat;
  width: 10px;
}

.hatchback {
  font-size: 12px;
  letter-spacing: 1px;
}

.hatchback-cars {
  color: #a80000;
}

.view-all {
  color: #a80000;
  font-weight: 600;
}

/* select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -prefix-appearance: none;

} */
select {
  background: white;
  /* border: 1px solid white; */
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  font-size: 12px;
  /* color: #3F3F3F; */

  /* Here's the code we need */
  /*-webkit-appearance: none;*/
  -moz-appearance: toolbox;
  /*-ms-appearance: none;*/
  /*-o-appearance: none;*/
  /*appearance: none;*/
}

select {
  padding-left: 40px;
  color: #d3d3d3;
}

select option {
  text-align: center;
}

.select-wrapper {
  position: relative;
}

select:not([size]):not([multiple])::-ms-expand {
    /* margin-left: 20px; */
    display: none;

}


.enter-city {
  width: 40%;
}

#exampleSelect option {
  /* margin-left: 20px; */
  width: 30%;
}

select {
  color: #d3d3d3;
  padding: 15px;
  padding-left: calc(30% - 1em);
}

.input-icons img {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.icon {
  width: 25px;
  height: 25px;
  top: 10px;
  left: 30px;
  text-align: center;
}

.react-autosuggest__container {
  width: 90%;
}

.react-autosuggest__input {
  width: 100%;
  /* color: #D3D3D3; */
  padding: 8px 7.5px;
  border: 2px solid lightgray;
  border-radius: 5px;
  padding-left: calc(25% - 1em);
  outline: none;
}

.react-autosuggest__suggestions-list {
  text-align: left;
  padding-top: 3px;
  padding-bottom: 3px;
}

select.form-control::-ms-expand {
  display: none;
}

.item .card-img-top {
  height: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  vertical-align: middle;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.item {
  text-align: center;
}

.card-title {
  font-size: 14px;
  font-weight: 700 !important;
  letter-spacing: 1px;
  padding-top: 5px;
  text-transform: uppercase;
}

.card-text:last-child {
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
}

.alice-carousel__prev-btn [data-area]::after {
  content: '\f053';
  position: relative;
  text-transform: capitalize;
  background-color: white;
  /*padding: 8px 10px 8px 10px;*/
  color: black;
  font-size: 14px;
  font-family: 'FontAwesome';
  border-radius: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.alice-carousel__next-btn [data-area]::after {
  content: '\f054';
  position: relative;
  text-transform: capitalize;
  background-color: white;
  /*padding: 8px 10px 8px 10px;*/
  color: black;
  font-size: 13px;
  font-family: 'FontAwesome';
  border-radius: 80%;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.alice-carousel__prev-btn {
  text-align: left;
  display: block;
}

.alice-carousel__prev-btn-wrapper {
  margin-top: -110px;
}

.alice-carousel__next-btn {
  text-align: right;
  width: 100%;
  display: block;
}

.alice-carousel__next-btn-wrapper {
  margin-top: -140px;
}

.alice-carousel__prev-btn-item.__inactive,
.alice-carousel__next-btn-item.__inactive {
  opacity: 1;
}

@media (max-width: 768px) {
  .card-deck .card {
    margin: 20px;
    margin: 0 5px 0 31px;
  }
}
.card-bodys-auto-parts {
}
@media (min-width: 990px) {
  .card-bodys-auto-parts {
    text-align: 'center';
    position: absolute;
    bottom: -10px;
  }
}

.car-body-wrapper {
}
@media (min-width: 990px) {
  .car-body-wrapper {
    bottom: 7px;
  }
}

.alice-carousel__dots {
  margin: 15px 0 5px;
}
